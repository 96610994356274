import React, { Suspense, memo, useEffect, useMemo, useRef } from 'react';
import {
  createError401QueriesHandler,
  createQueryCache,
  createQueryClient,
  queryDefaultOptions,
} from '@/services/useQuery';
import { store as reduxStore } from '@/redux/store';
// components
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from '@/features/notification/components/ToastContainer';
import { QueryClientProvider } from 'react-query';
import DndBackendProvider from '@/features/drag-n-drop/components/DndBackendProvider';
import { AuthProvider } from '@/features/authentication/context';
import RequireAuth from '@/features/authentication/components/RequireAuth';
import RequireUnauth from '@/features/authentication/components/RequireUnauth';
import PersistLogin from '@/features/authentication/components/PersistLogin';
// assets
import { icons } from '@/assets/icons';
import '@/scss/style.scss';

const LoginPage = React.lazy(() => import('./views/Pages/Login'));
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const appLoading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
React.icons = icons;

const App = () => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line dot-notation
    globalThis['DjWebVersion'] = process.env.REACT_APP_VERSION;
  }, []);

  const queryCache = useRef(createQueryCache());
  // Note: this memo callback for executing value of `queryClient` must run only once for app's entire life span:
  const queryClient = useMemo(() => {
    const queryClient = createQueryClient({
      defaultOptions: queryDefaultOptions,
      queryCache: queryCache.current,
    });

    // Setting global callback to handle 401 queries
    // Note: it's different to `defaultOptions` provided to the QueryClient: https://react-query.tanstack.com/reference/QueryCache#global-callbacks
    queryCache.current.config.onError = createError401QueriesHandler(queryClient);

    return queryClient;
  }, []);

  return (
    <Provider store={reduxStore}>
      <BrowserRouter>
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <DndBackendProvider>
              <Suspense fallback={appLoading}>
                <AppRoutes />
              </Suspense>
            </DndBackendProvider>
          </QueryClientProvider>
          <ToastContainer />
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  );
};

export const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<PersistLogin loadingComponent={appLoading} />}>
        <Route element={<RequireUnauth />}>
          <Route path="/login" element={<LoginPage />} />
        </Route>
        <Route element={<RequireAuth requireAllRoles />}>
          <Route path="/*" element={<TheLayout />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default memo(App);
