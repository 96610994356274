import jwtDecode from 'jwt-decode';
import memoizeOne from 'memoize-one';

// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export const decodeJwt = memoizeOne<(jwt: string | null | undefined, shouldDecodeHeader?: boolean) => any>(
  (jwt, shouldDecodeHeader = false) => {
    if (typeof jwt === 'string') {
      try {
        return jwtDecode(jwt, {
          header: shouldDecodeHeader,
        });
      } catch (err) {
        console.error(err);
      }
    }
    return null;
  },
);
