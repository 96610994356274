import { createContext, FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import type { Tokens } from '@/features/authentication/types';
import { googleClientId } from '@/features/authentication/constants';
import { GoogleOAuthProvider } from '@react-oauth/google';

export const authContext = createContext<AuthContext>({
  tokens: null,
  setTokens: () => {
    // this wont be used
  },
  removeTokens: () => {
    // this wont be used
  },
  shouldPersist: true,
  setShouldPersist() {
    // this wont be used
  },
});

export const AuthProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [tokens, setTokens] = useState<Tokens | null>(null);
  const removeTokens = useCallback(() => {
    setTokens(null);
  }, []);

  const [shouldPersist, setShouldPersist] = useState(true);

  const contextValue = useMemo<AuthContext>(
    () => ({
      tokens,
      setTokens,
      removeTokens,
      shouldPersist,
      setShouldPersist,
    }),
    [tokens, removeTokens, shouldPersist],
  );

  if (!googleClientId) throw new Error('Missing Google Client ID');

  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <authContext.Provider value={contextValue}>{children}</authContext.Provider>
    </GoogleOAuthProvider>
  );
};
AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

interface AuthContext {
  setTokens: (tokens: Tokens) => void;
  removeTokens: () => void;
  tokens: Tokens | null;
  shouldPersist: boolean;
  setShouldPersist: (bool: boolean) => void;
}
