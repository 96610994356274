import { api, getApiDefaultHeader, isAxiosError, setApiDefaultHeader } from '@/services/Api';
import Token, { AccessToken } from '@/features/authentication/models/Token';
import { accountRoles, artistRoles } from '@/features/user-management/constants';
import {
  saveItemToLocalStorage,
  getItemFromLocalStorage,
  removeItemFromLocalStorage,
} from '@/features/storage/controller';
import { catchErrorCallback } from '@/services/reportError';
import { decodeJwt } from '@/services/jwt';
import _isEmpty from 'lodash/isEmpty';
import memoizeOne from 'memoize-one';
import { tokenNames } from '@/features/authentication/constants';
import _set from 'lodash/set';
import createAuthRefreshInterceptor, { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh';
import type { ApiError, ApiRequestOptions, ApiInstance } from '@/services/Api';
import type { Roles, Tokens } from '@/features/authentication/types';

export const authHeaderName = 'Authorization';

export const generateAuthHeaderValue = (token: string) => {
  if (typeof token !== 'string' || !token) {
    throw new Error('Token must be a non-empty string');
  }
  return `Bearer ${token}`;
};

// Note: used after getting new tokens (login, refresh tokens)
export const setDefaultAuthHeader = (token?: string) => {
  if (typeof token === 'string' && !_isEmpty(token))
    setApiDefaultHeader(authHeaderName, generateAuthHeaderValue(token));
};

export const getDefaultAuthHeader = () => getApiDefaultHeader(authHeaderName);

export const removeDefaultAuthHeader = () => {
  delete api.defaults.headers.common[authHeaderName];
};

let authInterceptorId: ReturnType<typeof setupAuthCheck>;
// setupAuthCheck(api);
// these 2 methods below will always executed via react hook: useAuth()
export const removeAuthCheck = (interceptorId?: typeof authInterceptorId) => {
  api.interceptors.request.eject(interceptorId ?? authInterceptorId);
};

export const loginAsync = ({
  name,
  password,
  providerAccessToken,
  providerName,
}: {
  name?: string;
  password?: string;
  providerAccessToken?: string;
  providerName?: string;
}) =>
  catchErrorCallback(async () => {
    let loginPayload;
    if (providerName) {
      loginPayload = {
        provider_access_token: providerAccessToken,
        provider_name: providerName,
      };
    } else {
      loginPayload = {
        name,
        password,
      };
    }
    const { data } = await api.post(`/login`, loginPayload);
    return data;
  });

export const logoutAsync = (refreshToken?: string | null, axiosOptions: ApiRequestOptions = {}) =>
  catchErrorCallback(async () => {
    if (refreshToken) {
      try {
        await api.post('/logout', undefined, {
          ...axiosOptions,
          headers: {
            Authorization: generateAuthHeaderValue(refreshToken),
            ...(axiosOptions?.headers ?? {}),
          },
        });
      } catch (err) {
        // ignore, as refresh token could have been revoked before & the request could not be resolved
      }
    }
  });

export const authStatus = {
  isRefreshingToken: false, // if we are sending a refresh-tokens request, this flag is used for preventing checking in axios interceptor
  isFirstRefreshTokenRequest: true, // this flag is used to know whether the refresh-token request happens right after a full load/reload of any pages (check out `fetchNewTokensAsync`), it will be always false after the very first refresh-token request
};

export const checkIsTokenExpired = (token: string) => Token.isExpired(decodeJwt(token));

export const checkIsRoleGroup = (roles: Roles | null, roleGroupName: string) => {
  if (Array.isArray(roles) && roles.length > 0) {
    for (const role of roles) {
      if (typeof role === 'string' && role.endsWith(roleGroupName)) {
        return true;
      }
    }
  }
  return false;
};

export const checkIsRoleAdmin = (roles: Roles | null) => checkIsRoleGroup(roles, 'Administrator');
export const checkIsRoleMod = (roles: Roles | null) => checkIsRoleGroup(roles, 'Moderator');
export const checkIsRoleNotiPublisher = (roles: Roles | null) => checkIsRoleGroup(roles, accountRoles.NOTI_PUBLISHER);

export const checkIsAllowedToLogin = (accessToken: string) => {
  const userRoles = AccessToken.getAccountRoles(decodeJwt(accessToken));
  const isAdminAccount = checkIsRoleAdmin(userRoles);
  const isModAccount = checkIsRoleMod(userRoles);
  const isNotiPublisherAccount = checkIsRoleNotiPublisher(userRoles);
  const isArtistAccount = checkIsRoleArtist(userRoles);
  return isAdminAccount || isModAccount || isNotiPublisherAccount || isArtistAccount;
};

export const validateAccountRoles = (
  tokensByType: Tokens,
  resolve: () => void | Promise<void>,
  reject: () => void | Promise<void>,
) =>
  catchErrorCallback(async () => {
    const accessToken = tokensByType?.[tokenNames.ACCESS_TOKEN];

    if (typeof accessToken === 'string' && !_isEmpty(accessToken)) {
      if (checkIsAllowedToLogin(accessToken)) {
        // add auth header to all requests after logging in successfully:
        await resolve();

        return tokensByType;
      } else {
        await reject();
      }
    } else {
      // throw new Error('Access token is required');
    }
  });

const storageKeyOfTokens = 'tokens';

export const loadTokens = (cb?: () => void): Tokens | null => {
  try {
    cb?.();

    return JSON.parse(getItemFromLocalStorage(storageKeyOfTokens) as string); // nullable as token is stored in local storage, so the JSON.parse() could throw exception
  } catch (err) {
    return null;
  }
};

export const saveTokensToStorage = (tokens: Tokens) => {
  try {
    saveItemToLocalStorage(storageKeyOfTokens, JSON.stringify(tokens));
  } catch (err) {
    // ignore
  }
};

export const removeTokensFromStorage = () => {
  removeItemFromLocalStorage(storageKeyOfTokens);
};

// Note: Matching logic depends on option `requireAll`:
// - If `requireAll === true`, which returns `true` if ALL roles in `requiredRoles` appear in `availRoles`, so it will be "fail fast"
// - If `requireAll === false`, which returns `true` if ANY roles in `requiredRoles` appear in `availRoles`, so it will be "finish fast"
export const checkContainSpecificRoles = memoizeOne<
  (
    availRoles: Roles | null,
    requiredRoles?: Roles | any[],
    options?: {
      requireAll: boolean;
    },
  ) => boolean
>(
  (
    availRoles,
    requiredRoles = [],
    {
      requireAll = false,
    }: {
      requireAll?: boolean;
    } = {
      requireAll: false,
    },
  ) => {
    if (Array.isArray(availRoles) && Array.isArray(requiredRoles)) {
      // `for` loop is used instead of `Array.prototype.filter()` to be able to quit the loop ASAP if it finds JUST ONE matched role:
      for (const role of requiredRoles) {
        if (availRoles.includes(role)) {
          if (!requireAll) return true; // end this loop early (success fast) as we only need one of required roles to sastify this check [A]
        } else {
          if (requireAll) return false; // end this loop early (fail fast) as now we know that one of required roles is missing [B]]
        }
      }
      // so the loop has been finished, so we must be one of 2 cases:
      // 1. `requireAll === false` & `availRoles` has nothing in common with `requiredRoles` (otherwise the check would "success fast" [A] before this LOC is executed)
      // 2. `requireAll === true` & `availRoles` contains all items in `requiredRoles` (otherwise the check would "fail fast" [B] before this LOC is executed)
      return requireAll;
    }
    return false;
  },
);

export const checkIsRoleArtist = (roles: Parameters<typeof checkContainSpecificRoles>[0]) =>
  checkContainSpecificRoles(roles, artistRoles);

export const checkShouldAllowRole = (currentRoles: Roles, allowedRoles?: Roles) => {
  if (!Array.isArray(currentRoles) || currentRoles.length === 0) {
    // not allowed, as normal users do not have role
    return false;
  }

  for (let i = 0; i < currentRoles.length; i++) {
    const roleName = currentRoles[i];
    if (
      // default is no restriction, only restrict if `allowedRoles` is available:
      !Array.isArray(allowedRoles) ||
      (allowedRoles.length > 0 && allowedRoles.indexOf(roleName) > -1)
    ) {
      return true;
    }
  }
  return false;
};

export const filterNavItems = (
  userRoles: Roles,
  availNavItems: {
    allowedRoles?: Roles;
  }[] = [],
) => {
  if (!Array.isArray(userRoles) || userRoles.length === 0) {
    // for admin/special accounts which dont have artist roles
    return availNavItems;
  }
  return availNavItems.filter((navItem) => checkShouldAllowRole(userRoles, navItem.allowedRoles));
};

export const refreshTokensAsync = (refreshToken?: Tokens['refresh_token']) =>
  catchErrorCallback(async () => {
    const res = await api.post<Tokens>('token/refresh', undefined, {
      headers: refreshToken
        ? {
            Authorization: generateAuthHeaderValue(refreshToken),
          }
        : undefined,
    });
    return res.data;
  }) as Promise<Tokens>;

function _createAuthInterceptors(
  /* store: AccountModuleStore, */ axiosInstance: ApiInstance = api,
): ReturnType<typeof createAuthRefreshInterceptor> {
  // Function that will be called to refresh tokens:
  const refreshAuthLogic = async (failedRequestErr: Error): Promise<Tokens> => {
    try {
      const refreshTokenInLS = loadTokens()?.[tokenNames.REFRESH_TOKEN];
      if (!refreshTokenInLS) throw new Error('Refresh token is not available');
      const tokens = await refreshTokensAsync(refreshTokenInLS);
      const accessToken = tokens[tokenNames.ACCESS_TOKEN];
      setDefaultAuthHeader(accessToken);

      // persist tokens on client:
      saveTokensToStorage(tokens);

      // failed rerquest will be retried with new token:
      if (isAxiosError(failedRequestErr))
        _set(failedRequestErr, 'response.config.headers.Authorization', generateAuthHeaderValue(accessToken));

      return tokens;
    } catch (err) {
      if (
        isAxiosError(err) &&
        (checkAuthErrorType(err, 'tokenExpired') ||
          checkAuthErrorType(err, 'tokenRevoked') ||
          checkAuthErrorType(err, 'tokenInvalid') ||
          checkAuthErrorType(err, 'tokenInterchanged'))
      ) {
        removeTokensFromStorage();
        removeDefaultAuthHeader();
      }
      throw err;
    }
  };
  const authInterceptorId = createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {
    statusCodes: [401, 403, 400], // 400 error returned if Auth header contains invalid token
    pauseInstanceWhileRefreshing: true,
  });

  // Note: this interceptor is exec on both client AND SERVER, so we need to parse token in cookie in request body
  axiosInstance.interceptors.request.use((config: AxiosAuthRefreshRequestConfig) => {
    const accessTokenInLS = loadTokens()?.[tokenNames.ACCESS_TOKEN];
    // trying to not adding invalid `Authorization` header due to API server will validate (whenever it sees `Authorization` header) & mark this request as unauthorized, even though a few APIs are public (does not require access token in `Authorization` header):
    if (accessTokenInLS) {
      _set(
        config,
        `headers.${authHeaderName}`,
        config.headers?.[authHeaderName] ?? generateAuthHeaderValue(accessTokenInLS),
      );
    }

    // skip the auth interceptor if there is no token to refresh (user has never logged in - no tokens in cookie):
    // ref: https://github.com/Flyrell/axios-auth-refresh#skipping-the-interceptor
    if (!accessTokenInLS) {
      config.skipAuthRefresh = true;
    }

    return config;
  });

  return authInterceptorId;
}

// Note: this is a wrapper of `_createAuthInterceptors` function, doing an extra step:
// removing the old interceptor before adding new one, as there must be only one interceptor for auth.
export function setupAuthCheck(
  // store: Parameters<typeof _createAuthInterceptors>[0],
  // authInterceptorId?: number,
  axiosInstance: ApiInstance = api,
): ReturnType<typeof _createAuthInterceptors> {
  if (typeof authInterceptorId === 'number') {
    axiosInstance.interceptors.response.eject(authInterceptorId);
  }
  authInterceptorId = _createAuthInterceptors(axiosInstance);
  return authInterceptorId;
}

const errorCodeMap = {
  tokenExpired: ['00101004'],
  tokenRevoked: ['00101006', '00101008'],
  tokenInterchanged: ['00101002', '00101003'],
  missingAuthHeader: ['00101001'],
  tokenInvalid: ['00101007'],
};
export const checkAuthErrorType = <T extends AuthError = AuthError>(
  error: Error,
  type: AuthErrorType,
): error is ApiError<T> => {
  return (
    isAxiosError<T>(error) &&
    !!error.response?.data?.errorCode &&
    errorCodeMap[type].includes(error.response.data.errorCode)
  );
};

type AuthErrorType = keyof typeof errorCodeMap;

interface AuthError {
  errorCode: string;
  errorType: string;
  description: string;
}
