import _isEqual from 'lodash/isEqual';
import _isFunction from 'lodash/isFunction';
import _uniqWith from 'lodash/uniqWith';
import _intersectionWith from 'lodash/intersectionWith';
import memoizeOne from 'memoize-one';

export const checkIsBrowser = () => typeof window !== 'undefined';

// Note: `getCommonItems` is memoized due to the fact that both `getCommonItems` & `checkHasCommonItem` will be called together (in the same function) most of the times, so doing the same task outside & inside of `checkHasCommonItem` is a bit redundant.
export const getCommonItems = memoizeOne(_intersectionWith); // temporarily disable memoization due to `memoize-one` does not convert types well (the type of return value is `unknown[]` instead of `string[]` if both arrays' type is `string[]`).
export const checkHasCommonItem = <T1 = unknown, T2 = unknown>(
  arr1: T1[] = [],
  arr2: T2[] = [],
  comparator: Parameters<typeof _intersectionWith>[2],
) => {
  if (Array.isArray(arr1) && Array.isArray(arr2) && arr1.length > 0 && arr2.length > 0) {
    const commonItems = getCommonItems(arr1, arr2, comparator);
    return commonItems.length > 0;
  }
  return false;
};

// this helper check whether an array contains duplicated objects (objects that have same properies & values)
export const hasDuplicatedObjects = (list: any[] = [], compareObjectFn = _isEqual) => {
  if (!Array.isArray(list)) throw new Error('First argument must be array');
  if (!_isFunction(compareObjectFn)) throw new Error('Second argument must be function');
  if (list.length < 2) return false;

  const uniqueList = _uniqWith(list, compareObjectFn);
  // const uniqueList = [];
  // list.forEach((item) => {
  //   let isMatched = false;
  //   for (let i = 0; i < uniqueList.length; i++) {
  //     if (compareObjectFn(uniqueList[i], item)) {
  //       isMatched = true;
  //       return true;
  //     }
  //   }
  //   if (!isMatched) {
  //     uniqueList.push(item);
  //   }
  // });
  return uniqueList.length !== list.length;
};

export const validateInstance = (object: any, classConstructor: FunctionConstructor) => {
  if (!(object instanceof classConstructor)) {
    throw new Error(`Passed object is not an instance of ${classConstructor.name}`);
  }
  return object;
};
