import type { AccessTokenData } from '@/features/authentication/types';

export default class Token {
  // return number representing expiry time in milliseconds
  // NOTE: expiry time from JWT is in seconds
  static getExpiredTime = (parsedToken: Record<string, any>) => {
    try {
      return (parsedToken.exp as number) * 1000;
    } catch (err) {
      return 0;
    }
  };

  static isExpired = (parsedToken: Record<string, any>) => {
    // Check the timestamp is at least 1000ms after the current moment.
    // 10000ms is appoximately time for sending refresh-token request &
    // make sure the token is still valid when the server receives our request

    const exp = Token.getExpiredTime(parsedToken);
    return exp - 10000 < Date.now();
  };

  constructor() {
    throw new Error('This class cannot be used to create instances');
  }
}

export class AccessToken extends Token {
  static getAccountRoles = (parsedAccessToken: AccessTokenData | undefined | null) => parsedAccessToken?.roles ?? null;
}
