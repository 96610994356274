import { sygnet } from './sygnet';
import { logo } from './logo';
import { logoNegative } from './logo-negative';

import {
  cibGoogle,
  cilAccountLogout,
  cilAlbum,
  cilArrowCircleLeft,
  cilArrowCircleRight,
  cilArrowLeft,
  cilArrowRight,
  cilArrowThickFromBottom,
  cilArrowThickFromTop,
  cilBadge,
  cilBarChart,
  cilBank,
  cilBell,
  cilChartLine,
  cilCheckCircle,
  cilCog,
  cilCloudDownload,
  cilCloudUpload,
  cilEnvelopeClosed,
  cilEqualizer,
  cilImage,
  cilInfo,
  cilList,
  cilLockLocked,
  cilMap,
  cilMic,
  cilMove,
  cilMusicNote,
  cilPlaylistAdd,
  cilReload,
  cilSettings,
  cilSpeedometer,
  cilUser,
  cilUserFemale,
  cilVoice,
  cilVoiceOverRecord,
  cilWarning,
  cilXCircle,
} from '@coreui/icons';

export const icons = Object.assign(
  {},
  {
    sygnet,
    logo,
    logoNegative,
  },
  {
    cibGoogle,
    cilAccountLogout,
    cilAlbum,
    cilArrowCircleLeft,
    cilArrowCircleRight,
    cilArrowLeft,
    cilArrowRight,
    cilArrowThickFromBottom,
    cilArrowThickFromTop,
    cilBadge,
    cilBarChart,
    cilBank,
    cilBell,
    cilChartLine,
    cilCheckCircle,
    cilCog,
    cilCloudDownload,
    cilCloudUpload,
    cilEnvelopeClosed,
    cilEqualizer,
    cilImage,
    cilInfo,
    cilList,
    cilLockLocked,
    cilMap,
    cilMic,
    cilMove,
    cilMusicNote,
    cilPlaylistAdd,
    cilReload,
    cilSettings,
    cilSpeedometer,
    cilUser,
    cilUserFemale,
    cilVoice,
    cilVoiceOverRecord,
    cilWarning,
    cilXCircle,
  },
);
