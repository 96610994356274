import { checkIsBrowser } from '@/helpers/check';

// see: https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Testing_for_availability
export const checkIsStorageAvailable = (type: Storage = 'localStorage') => {
  if (!checkIsBrowser()) {
    return false;
  }

  let storage;
  try {
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
};

export const saveItem = (key: string, value: string, storage: Storage = 'localStorage') =>
  window[storage].setItem(key, value);

export const getItem = (key: string, storage: Storage = 'localStorage') => window[storage].getItem(key);

export const removeItem = (key: string, storage: Storage = 'localStorage') => window[storage].removeItem(key);

export type Storage = 'localStorage' | 'sessionStorage';
