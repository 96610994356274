import PropTypes from 'prop-types';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

// replacing DndProvider with DragDropContext as there must be only one HTML5 Backend at the same time (error happens while transitioning between 2 pages that has dnd features)
const DndBackendProvider = ({ children }) => <DndProvider backend={HTML5Backend}>{children}</DndProvider>;
DndBackendProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DndBackendProvider;
