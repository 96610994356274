import { notifyErrorTryAgain } from '@/features/notification/controller';
import {
  useMutation,
  useQueryClient,
  QueryClient,
  QueryCache,
  MutationCache,
  DefaultOptions,
  QueryKey,
  Query,
  // UseQueryOptions as OrigUseQueryOptions,
} from 'react-query';
import ms from 'ms';
import type { ApiError } from '@/services/Api';
export * from 'react-query';

export const createQueryKeyFactory = (
  entityQueryKey: string,
  generateCustomQueryKeys: (keysFactory: Record<string, any>) => Record<string, any> = () => ({}),
) => {
  const keysFactory = {
    all: [entityQueryKey] as any[],
    lists: () => keysFactory.all.concat('list'),
    listsPaging: () => keysFactory.lists().concat('paging'),
    listPaging: (pageIndex?: number, pageSize?: number, params?: Record<string, any>) =>
      keysFactory.listsPaging().concat(pageIndex ?? [], pageSize ?? [], params ?? []),
    listAll: (params?: Record<string, any>) => keysFactory.lists().concat('all', params ?? []),
    details: () => keysFactory.all.concat('detail'),
    detail: (id: number, params?: Record<string, any>) => keysFactory.details().concat(id, params ?? []),
  };
  return Object.assign(keysFactory, generateCustomQueryKeys?.(keysFactory) ?? {});
};
export const createQueryCache = (config?: QueryCacheConfig) => new QueryCache(config);
export const createQueryClient = (config?: QueryClientConfig) => new QueryClient(config);

export const createError401QueriesHandler = (queryClient: QueryClient) => async (err: any) => {
  if (err?.isAxiosError && err?.response?.status === 401) {
    await queryClient.cancelQueries();
    await queryClient.invalidateQueries();
  }
};

export const queryDefaultOptions: DefaultOptions = {
  mutations: {
    useErrorBoundary: false,
  },
  queries: {
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
    staleTime: ms('1h'),
    cacheTime: ms('15m'),
    retry: (failureCount, err) => {
      if ((err as ApiError).isAxiosError && (err as ApiError).response?.status === 401) {
        return failureCount < 1; // failed-auth requests will be refetched by axios interceptors
      }
      return failureCount < 3;
    },
  },
};

export const useQueryCache = () => useQueryClient().getQueryCache();

// helpers:
export const handleMutateError = (_err?: any) => {
  notifyErrorTryAgain();
};
export const handleMutateSuccess = () => {
  // do nothing, as the data will be re-fetched & updated into redux store & components will reflect those changes on UI
};

// hooks:
export const useMutate = (
  queryKey: QueryKey,
  { onError = handleMutateError, onSuccess = handleMutateSuccess, ...mutateOptions } = {
    onError: handleMutateError,
    onSuccess: handleMutateSuccess,
  },
) => {
  const queryClient = useQueryClient();
  return useMutation(async (mutateFn: () => void | Promise<void>) => await mutateFn(), {
    // Optimistically update the cache value on mutate, but store
    // the old value and return it so that it's accessible in case of
    // an error
    onMutate: async () => {
      await queryClient.cancelQueries(queryKey);

      // store the old value and return it so that it's accessible in case of an error:
      return queryClient.getQueryData(queryKey);
    },
    // On failure, roll back to the previous value
    onError:
      onError ||
      (async (err, variables, previousValue) => {
        await queryClient.setQueryData(queryKey, previousValue);
        handleMutateError(err);
      }),
    onSuccess,
    // After success or failure, refetch
    onSettled: async () =>
      await queryClient.invalidateQueries(queryKey, {
        refetchInactive: true,
      }),
    ...mutateOptions,
  });
};

interface QueryCacheConfig {
  onError?: (error: unknown, query: Query<unknown, unknown, unknown>) => void;
  onSuccess?: (data: unknown, query: Query<unknown, unknown, unknown>) => void;
}
interface QueryClientConfig {
  queryCache?: QueryCache;
  mutationCache?: MutationCache;
  defaultOptions?: DefaultOptions;
}
