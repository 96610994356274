import type {
  AccountData,
  AccountState,
  ActionTypes,
  FetchAccountAction,
  RemoveAccountAction,
} from '@/features/account/types';
import type { ActionCreator, Reducer } from 'redux';

export const actionTypes: ActionTypes = {
  FETCH_ACCOUNT: 'account/fetch',
  REMOVE_ACCOUNT: 'account/remove',
};

export const fetchAccountData: ActionCreator<FetchAccountAction> = (accountData: AccountData): FetchAccountAction => ({
  type: actionTypes.FETCH_ACCOUNT,
  payload: accountData,
});

export const removeAccountData: ActionCreator<RemoveAccountAction> = () => ({
  type: actionTypes.REMOVE_ACCOUNT,
});

const initialState: AccountState = {
  data: null,
};

export const reducer: Reducer<AccountState, FetchAccountAction | RemoveAccountAction> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.FETCH_ACCOUNT:
      return {
        ...state,
        data: (action as FetchAccountAction).payload,
      };
    case actionTypes.REMOVE_ACCOUNT:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};
