import { toast } from 'react-toastify';
import type { ToastOptions } from 'react-toastify';

export const TOAST_TYPE = toast.TYPE;
export const TOAST_POSITION = toast.POSITION;

export { ToastContainer } from 'react-toastify';

export const hideToast: typeof toast.dismiss = (...args) => toast.dismiss(...args);
export const updateToast: typeof toast.update = (...args) => toast.update(...args);
export const checkIsActive: typeof toast.isActive = (...args) => toast.isActive(...args);

export const showToast = (
  content: any,
  {
    duplicable = true,
    ...toastOptions
  }: {
    duplicable?: boolean;
  } & ToastOptions = {
    duplicable: true,
  },
) => {
  if (!duplicable && typeof content === 'string' && toast.isActive(content)) {
    return toast.update(content, toastOptions);
  }

  return toast(content, {
    ...(toastOptions ?? {}),
    toastId: toastOptions?.toastId ?? (!duplicable && typeof content === 'string' ? content : undefined),
  });
};

export type {
  Id as NotificationId,
  ToastOptions as NotificationOptions,
  ToastContent as NotificationContent,
} from 'react-toastify';
