import { createStore, applyMiddleware, compose } from 'redux';
import createRootReducer from '@/redux/reducer';
import thunk from 'redux-thunk';
// import logger from 'redux-logger';
import type { AccountState } from '@/features/account/types';

const middlewares = [thunk];
if (process.env.NODE_ENV === 'development') {
  // Note: `logger` must be the last middleware in chain, otherwise it will log thunk and promise, not actual actions (ref: https://github.com/LogRocket/redux-logger/issues/20)
  // middlewares.push(logger);
}

export const configureStore = (initialState: Partial<AppInitialState>) =>
  createStore(createRootReducer(), initialState, compose(applyMiddleware(...middlewares)));

export const store = configureStore({});

export interface AppInitialState extends Record<string, unknown> {
  account: AccountState;
}
export type AppRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
