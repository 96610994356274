import { ValueOf } from '@/helpers/type';
import { api } from '@/services/Api';
import {
  NotificationContent,
  NotificationId,
  NotificationOptions,
  showToast,
  TOAST_TYPE,
  updateToast,
} from '@/services/notifyToastMessage';
import { catchErrorCallback } from '@/services/reportError';
import {
  destinationTypes,
  entityTypes,
  notiFormRecipientCategories,
  screenNames,
} from '@/features/notification/constants';

export {
  hideToast as dismissNotification,
  showToast as notify,
  checkIsActive as checkIsNotificationActive,
  TOAST_TYPE,
  TOAST_POSITION,
} from '@/services/notifyToastMessage';

export const updateNotification = (
  id: NotificationId,
  renderContent: NotificationContent,
  toastOptions: NotificationOptions = {},
) =>
  updateToast(id, {
    render: renderContent,
    ...toastOptions,
  });

export const notifySuccess = (content: NotificationContent, toastOptions: ToastOptionsWithPredefinedType = {}) =>
  showToast(content, {
    type: TOAST_TYPE.SUCCESS,
    ...toastOptions,
  });

export const notifyInfo = (content: NotificationContent, toastOptions: ToastOptionsWithPredefinedType = {}) =>
  showToast(content, {
    type: TOAST_TYPE.INFO,
    ...toastOptions,
  });

export const notifyWarning = (content: NotificationContent, toastOptions: ToastOptionsWithPredefinedType = {}) =>
  showToast(content, {
    type: TOAST_TYPE.WARNING,
    ...toastOptions,
  });

export const notifyError = (content: NotificationContent, toastOptions: ToastOptionsWithPredefinedType = {}) =>
  showToast(content, {
    type: TOAST_TYPE.ERROR,
    ...toastOptions,
  });

export const notifyErrorTryAgain = (toastOptions: Parameters<typeof notifyError>[1] = {}) =>
  notifyError('Đã xảy ra lỗi, hãy thử lại!', toastOptions);

export const formatNotificationFormData = (values: Record<string, any>): NotificationFormData => {
  // format common fields:
  const contents = values.contents;
  if (typeof contents === 'string') {
    values.contents = contents.replace(/\n/gim, '\n\n');
  }
  const sound = values.sound;
  if (sound === 'none') {
    values.sound = null;
  }

  let sendAfter = values.send_after;
  // `sendAfter` will be either string or Date object because the validation schema will convert this value to Date object:
  if (typeof sendAfter === 'string' && sendAfter.length > 0) {
    sendAfter = new Date(sendAfter);
  }
  if (sendAfter instanceof Date) {
    values.send_after = Math.round(sendAfter.getTime() / 1000);
  } else values.send_after = null;

  // remove optional fields based on `destination_type`:
  if (values.destination_type !== destinationTypes.CONTENT) {
    delete values.entity_type;
    delete values.entity_id;
  }
  if (values.destination_type === destinationTypes.SCREEN) {
    delete values.url;
  } else {
    delete values.screen_name;
  }

  return values as NotificationFormData;
};
export const postNotification = (data: NotificationFormData, axiosOptions = {}) =>
  catchErrorCallback(async () => {
    const res = await api.post('/notification/experimental/send', data, axiosOptions);
    return res;
  });

export interface NotificationFormData extends Record<string, any> {
  headings: string;
  contents: string;
  global_image: string;
  sound: 'quay_len' | null;
  send_after: number | null;
  recipient_category: typeof notiFormRecipientCategories[number];
  destination_type: ValueOf<typeof destinationTypes>;
  url?: string;
  entity_id?: string;
  entity_type?: typeof entityTypes[number];
  screen_name?: typeof screenNames[number];
}

export type ToastOptionsWithPredefinedType = Omit<NotificationOptions, 'type'>;
