export const notiFormRecipientCategories = ['SELF', 'PUBLIC'] as const;
export const notiFormSounds = {
  none: '(Âm báo của hệ thống)',
  quay_len: 'Quẩy lên',
} as const;
export const destinationTypes = {
  CONTENT: 'CONTENT',
  SCREEN: 'SCREEN',
  EXTERNAL_URL: 'EXTERNAL_URL',
} as const;
export const screenNames = ['subscription_management', 'charts', 'profile', 'library', 'home'] as const;
export const entityTypes = ['TRACK', 'RECORDING', 'ARTIST', 'PLAYLIST'] as const;
