import { FC, memo, ReactElement } from 'react';
import { usePersistLogin } from '@/features/authentication/reactHooks';
// components
import { Outlet } from 'react-router-dom';

const PersistLogin: FC<Props> = ({ loadingComponent }) => {
  const { isLoading, shouldPersist } = usePersistLogin();
  return !shouldPersist ? <Outlet /> : isLoading ? loadingComponent : <Outlet />;
};

interface Props {
  loadingComponent: ReactElement;
}

export default memo(PersistLogin);
