export const reportErrorToSentry = (errorInfo: any) => {
  // use Sentry as app's error reporter
};

export const reportError = (error: Error, errorInfo?: any) => {
  reportErrorToSentry(errorInfo || error);
  logErrorToConsole(error);
};

export const logErrorToConsole: typeof global.console.error = (...args) => {
  global.console.error(...args);
};

// wrap sync/async callback in a common try-catch block
export const catchErrorCallback = async <T = unknown>(
  cb: () => T | Promise<T>,
  {
    rethrowError = true,
  }: {
    rethrowError?: boolean;
  } = {
    rethrowError: true,
  },
) => {
  try {
    // the keyword 'await' below is used intentionally in return statement to catch any errors but not deliver them to outer function, see note:
    // ref: "return await promiseValue vs. return promiseValue" on MDN: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/async_function#return_await_promiseValue_vs._return_promiseValue
    return await cb();
  } catch (err) {
    reportError(err as Error);
    if (rethrowError) throw err;
  }
};
