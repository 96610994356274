import { useCallback, useContext, useEffect, useState } from 'react';
import {
  loadTokens,
  setDefaultAuthHeader,
  checkIsAllowedToLogin,
  checkIsTokenExpired,
  checkContainSpecificRoles,
  logoutAsync,
  loginAsync,
  validateAccountRoles,
  refreshTokensAsync,
  saveTokensToStorage,
  removeTokensFromStorage,
  removeAuthCheck,
  setupAuthCheck,
  removeDefaultAuthHeader,
} from '@/features/authentication/controller';
import { AccessToken } from '@/features/authentication/models/Token';
import { accountRoles, artistRoles } from '@/features/user-management/constants';
import _isEmpty from 'lodash/isEmpty';
import { decodeJwt } from '@/services/jwt';
import { authContext } from '@/features/authentication/context';
import { tokenNames } from '@/features/authentication/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { wait } from '@/helpers/async';
import type { Tokens } from '@/features/authentication/types';
import { api } from '@/services/Api';

export const useAuthContext = () => useContext(authContext);

const useHandleLoginSuccess = () => {
  const navigate = useNavigate();
  const { setTokens } = useAuthContext();
  const location = useLocation();
  const redirectTo = location.state?.from?.pathname ?? '/'; // if user is redirected by component `RequireAuth`, current location's state will be `{ from: [original location where user was before being redirected] }` (see component `RequireAuth`)
  return useCallback(
    (tokensByType: Tokens) => {
      // we must validate account's roles before allow logging in:
      return validateAccountRoles(
        tokensByType,
        async () => {
          setDefaultAuthHeader(tokensByType[tokenNames.ACCESS_TOKEN]); // should do this once after logging in, to avoid any race condition bug with any requests sent right after saving tokens into auth context
          setTokens(tokensByType);
          await wait(400);
          navigate(redirectTo, { replace: true });
          saveTokensToStorage(tokensByType);
        },
        () => {
          throw new Error('This account is not allowed to login');
        },
      );
    },
    [setTokens, navigate, redirectTo],
  );
};

export const useLogin = () => {
  const handleLoginSuccess = useHandleLoginSuccess();
  return useCallback(
    async (name: string, password: string) => {
      const tokensByType = await loginAsync({
        name,
        password,
      });
      return handleLoginSuccess(tokensByType as Tokens);
    },
    [handleLoginSuccess],
  );
};

export const useLoginWithProvider = () => {
  const handleLoginSuccess = useHandleLoginSuccess();
  return useCallback(
    async (providerAccessToken: string, providerName = 'facebook') => {
      const tokensByType = await loginAsync({
        providerAccessToken,
        providerName,
      });
      return handleLoginSuccess(tokensByType as Tokens);
    },
    [handleLoginSuccess],
  );
};

export const useLogout = () => {
  const { removeTokens } = useAuthContext();
  const navigate = useNavigate();
  return useCallback(
    async (refreshToken: Tokens['refresh_token']) => {
      await logoutAsync(refreshToken);
      removeDefaultAuthHeader();
      removeTokens();
      navigate('/login');
      removeTokensFromStorage();
    },
    [navigate, removeTokens],
  );
};

// Note: this hook is either used in component `PersistLogin` or `AppRoutes`
export const usePersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { setTokens, shouldPersist } = useAuthContext();
  const logout = useLogout();

  useEffect(() => {
    if (!isLoading) return;
    if (!shouldPersist) {
      return setIsLoading(false); // skip persisting
    }

    const tokens = loadTokens();
    const accessToken = tokens?.[tokenNames.ACCESS_TOKEN];
    const refreshToken = tokens?.[tokenNames.REFRESH_TOKEN];
    try {
      if (!refreshToken) {
        // does not need to do anything, the routing will take care the rest
      } else if (checkIsTokenExpired(refreshToken)) {
        logout(refreshToken);
      } else {
        // setup auth header for the first load (retrieve tokens from `localStorage`)
        setDefaultAuthHeader(accessToken);
        setTokens(tokens);

        // this effect makes sure that manually-injected tokens (in `localStorage`) of normal accounts cannot access our admin pages
        if (typeof accessToken === 'string' && !_isEmpty(accessToken)) {
          if (!checkIsAllowedToLogin(accessToken)) {
            logout(refreshToken);
          }
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  }, [logout, isLoading, setTokens, shouldPersist]);

  useSetupAuthInterceptors(isLoading);

  return { isLoading, shouldPersist };
};

// - NOTE: this hook will be used as main auth hook
export const useAuth = () => {
  return {
    isLoggedIn: useCheckLoggedIn(),
    ...useAuthContext(),
    // isLoading: usePersistLogin(),
  };
};

// check login state by checking refresh token is available & not expired:
export const useCheckLoggedIn = () => {
  // - NOTE: for our login flow with refresh_token, the expiration of access token
  // is not important to check here anymore as long as login state is being considered,
  // as we will intercept all API requests to check expiry time of access token
  // (& refresh it if it's expired)
  const refreshToken = useAuthContext().tokens?.[tokenNames.REFRESH_TOKEN];
  return refreshToken ? !checkIsTokenExpired(refreshToken) : false;
};

export const useAccountRoles = () =>
  AccessToken.getAccountRoles(decodeJwt(useAuthContext().tokens?.[tokenNames.ACCESS_TOKEN]));

export const useCheckAccountRoles = (
  requiredRoles: Parameters<typeof checkContainSpecificRoles>[1],
  options?: Parameters<typeof checkContainSpecificRoles>[2],
) => {
  const hasLoggedIn = useCheckLoggedIn();
  const userRoles = useAccountRoles();
  return hasLoggedIn && checkContainSpecificRoles(userRoles, requiredRoles, options);
};

export const useCheckIsArtistAccount = () => useCheckAccountRoles(artistRoles);

export const useCheckIsSuperAdminAccount = () => useCheckAccountRoles([accountRoles.SUPER_ADMIN]);

export const useCheckIsContentModAccount = () => useCheckAccountRoles([accountRoles.CONTENT_MOD]);

export const useRefreshTokens = () => {
  const { setTokens, tokens } = useAuthContext();
  const refreshToken = tokens?.[tokenNames.REFRESH_TOKEN];
  return useCallback(async () => {
    const tokens = await refreshTokensAsync(refreshToken);
    setTokens(tokens);
  }, [refreshToken, setTokens]);
};

export const useSetupAuthInterceptors = (isLoadingTokens?: boolean) => {
  useEffect(
    () => {
      let authInterceptorId: ReturnType<typeof setupAuthCheck>;
      /* if (isLoadingTokens) */ setupAuthCheck(api);
      return () => {
        removeAuthCheck(authInterceptorId);
      };
    },
    [
      // isLoadingTokens
    ],
  );

  // make sure the latest access token is set to `Authorization` header of later requests:
  const { tokens } = useAuthContext();
  const accessToken = tokens?.[tokenNames.ACCESS_TOKEN];
  useEffect(() => {
    if (typeof accessToken === 'string') setDefaultAuthHeader(accessToken);
  }, [accessToken]);
};
