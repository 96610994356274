import { saveItem, getItem, removeItem, checkIsStorageAvailable } from '@/services/storage';

export const checkIsLocalStorageAvailable = () => checkIsStorageAvailable();
export const checkIsSessionStorageAvailable = () => checkIsStorageAvailable('sessionStorage');

export const saveItemToLocalStorage = (key: string, value: string) => saveItem(key, value);

export const getItemFromLocalStorage = (key: string) => getItem(key);

export const removeItemFromLocalStorage = (key: string) => removeItem(key);
