import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, HeadersDefaults } from 'axios';
import _set from 'lodash/set';
import _get from 'lodash/get';
import type { ValueOf } from '@/helpers/type';
import _isUndefined from 'lodash/isUndefined';

export { default as parseLinkHeader } from 'parse-link-header';

export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT ?? '';
export const axiosBaseConfig: AxiosRequestConfig = Object.freeze({
  headers: {},
  baseURL: apiEndpoint + '/api/',
  timeout: 10000,
  transitional: {
    clarifyTimeoutError: true,
  },
});

export const createApiInstance = (config = axiosBaseConfig) => axios.create(config);

export const api = createApiInstance();

export const invalidDataErrorMsg = 'Response data is invalid';

export const setApiDefaultHeader = (
  header: keyof AxiosRequestHeaders,
  val: ValueOf<AxiosRequestHeaders>,
  {
    instance = api,
    method = 'common',
  }: {
    instance?: AxiosInstance;
    method?: keyof HeadersDefaults;
  } = {
    instance: api,
    method: 'common',
  },
) => {
  _set(instance, `defaults.headers.${method}.${header}`, val); // instance.defaults.headers[method][header] = val;
};
export const getApiDefaultHeader = (
  header: keyof AxiosRequestHeaders,
  {
    instance = api,
    method = 'common',
  }: {
    instance?: AxiosInstance;
    method?: keyof HeadersDefaults;
  } = {
    instance: api,
    method: 'common',
  },
) => _get(instance, `defaults.headers.${method}.${header}`);

export const isAxiosError = <T>(err: unknown): err is AxiosError<T> => (err as AxiosError).isAxiosError;
export const isCancelError = (err: AxiosError) => axios.isCancel(err);
export const isTimeoutError = (err: AxiosError) => err.code === 'ETIMEDOUT';
export const isNetworkError = (err: any) =>
  _isUndefined(err.response) && !_isUndefined(err?.request) && !err.request?.status; // `err` has type `any` due to a TS error if we set its type to `AxiosError`: "Property `status` does not exist on type `AxiosError<any, any>`" (ref: https://github.com/axios/axios/issues/383#issuecomment-234079506, https://axios-http.com/docs/handling_errors)

export type {
  AxiosRequestConfig as ApiRequestOptions,
  AxiosRequestHeaders as ApiRequestHeaders,
  AxiosInstance as ApiInstance,
  AxiosError as ApiError,
  AxiosResponse as ApiResponse,
  AxiosResponseHeaders as ApiResponseHeaders,
} from 'axios';
