// this map must be kept in sync with server's
export const accountRoles = Object.freeze({
  SUPER_ADMIN: 'SuperAdministrator' as const,
  USER_MOD: 'UserModerator' as const,
  CONTENT_MOD: 'ContentModerator' as const,
  DJ: 'DJ' as const,
  PRODUCER: 'Producer' as const,
  CREATOR: 'Creator' as const,
  NOTI_PUBLISHER: 'NotificationPublisher' as const,
});

export const allRoles = Object.values(accountRoles);

export const artistRoles = [accountRoles.DJ, accountRoles.PRODUCER, accountRoles.CREATOR];
