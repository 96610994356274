import { memo, FC } from 'react';
import { useAuth, useCheckAccountRoles } from '@/features/authentication/reactHooks';
// components
import { Outlet, Navigate, useLocation } from 'react-router-dom';

// Note: 2 special cases:
// 1. `allowedRoles == [] && requireAllRoles === false`: BLOCK all account roles from accessing this route
// 2. `allowedRoles == [] && requireAllRoles === true`: ALLOW any account roles to access this route

const RequireAuth: FC<Props> = ({ allowedRoles, requireAllRoles, ...props }) => {
  const { isLoggedIn } = useAuth();
  const location = useLocation();

  const isAllowed = useCheckAccountRoles(allowedRoles, {
    requireAll: requireAllRoles as boolean,
  });

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return isAllowed ? <Outlet /> : <Navigate to="/unauthorized" replace />;
};

RequireAuth.defaultProps = {
  allowedRoles: [],
  requireAllRoles: false, // by default, if `requireAllRoles` is not explicitly set to true, this component blocks all user from accessing nested routes
};

interface Props {
  allowedRoles?: string[];
  requireAllRoles?: boolean;
}

export default memo(RequireAuth);
