import { FC, memo } from 'react';
import { useCheckLoggedIn } from '@/features/authentication/reactHooks';
// components
import { Outlet, Navigate } from 'react-router-dom';

const RequireUnauth: FC = () => {
  const isLoggedIn = useCheckLoggedIn();
  if (isLoggedIn) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

export default memo(RequireUnauth);
